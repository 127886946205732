import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  userName: '',
  companyName: '',
  email: '',
  phoneNumber: '',
  isCheckingEmail: false,
  validationEmailMessage: '',
  submitting: false,
  isSuccess: false,
};

export default (state: ContainerState = initialState, action: ContainerActions) => {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.CHECK_EMAIL_REQUEST:
      return {
        ...state,
        isCheckingEmail: true,
        validationEmailMessage: '',
      };
    case ActionTypes.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        isCheckingEmail: false,
        validationEmailMessage: '',
      };
    case ActionTypes.CHECK_EMAIL_FAILURE:
      return {
        ...state,
        isCheckingEmail: false,
        validationEmailMessage: payload,
      };
    case ActionTypes.CONTACT_US_SUBMIT:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.CONTACT_US_REQUEST: {
      return {
        ...state,
        ...payload,
        isSuccess: false,
        submitting: true,
      };
    }
    case ActionTypes.CONTACT_US_SUCCESS: {
      return {
        ...state,
        ...payload,
        isSuccess: true,
        submitting: false,
      };
    }
    case ActionTypes.CONTACT_US_FAILURE: {
      return {
        ...state,
        ...payload,
        isSuccess: false,
        submitting: false,
      };
    }
    default:
      return state;
  }
};
